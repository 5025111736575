<template>
  <div>
    <v-app class="hide-scroll">
      <v-container 
        class="register-user-container hide-scroll-bar overflow-y-auto" 
        :style="registrationDetails.institution ? 
        {'background-image': 'url(' + (this.registrationDetails.institution.image) + ')'} : {}" fill-height fluid
      >
        <v-row justify="center" align="center">
          <v-col cols="12" md="8" align-self="center">
            <v-card elevation="0" class="px-5">
              <v-card-title class="pa-0 py-2 custom-title justify-center">
                <img src="../../assets/logo.png" alt="IFI" width="50" />
                <span>{{appTitle}}</span>
              </v-card-title>
              <v-form @submit.prevent="register">
                <v-stepper v-model="vStepperStep">
                  <v-stepper-header elevation="0">
                    <v-stepper-step :complete="vStepperStep > 1" step="1">
                      Select Institution
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="vStepperStep > 2" step="2">
                      Personal Details
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="vStepperStep > 3" step="3">
                      Select Classroom
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content step="1" class="pa-0">
                      <v-row class="custom-row-height pa-0 ma-0" justify="center" align="center">
                        <v-col class="pa-5" cols="12" sm="10" lg="8" justify="center" align="center">
                          <v-text-field
                            v-model.trim="registrationDetails.pincode" label="Enter Pincode" solo
                            clearable rounded class="mb-3" maxlength="6"
                            @keypress="isNumber"
                            :error-messages="formErrors.pincode"
                            @input="debouncedGetFilteredInstitutionsByPincode(registrationDetails.pincode)"
                            hide-details="auto"
                          >
                          </v-text-field>
                          <v-autocomplete 
                            ref="select-institution-dropdown" 
                            v-model="registrationDetails.institution"
                            return-object 
                            :items="filteredInstitutions" 
                            item-text="name"
                            :label="otherInstitutionLabel || defaultLabel" 
                            solo rounded 
                            hide-details="auto"
                            :search-input.sync="filteredInstitutionsByNameOrCode"
                            @input="onInstitutionSelect" 
                            :error-messages="formErrors.institution" no-filter
                            :menu-props="{ closeOnContentClick: true }"
                            class="mb-3">
                            <template v-slot:append-item>
                              <v-divider></v-divider>
                              <v-list-item class="mb-n2" @click="onOtherInstitutionSelect">
                                <v-list-item-content>
                                  My institution is not in the list
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                          <v-text-field v-if="default_institution_registration"
                            v-model.trim="registrationDetails.custom_institution_name" label="Institution Name *" solo
                            clearable rounded class="mb-3" maxlength="100"
                            :error-messages="formErrors.custom_institution_name"
                            @input="formValidityCheckers.custom_institution_name" hide-details="auto"
                          >
                          </v-text-field>
                          <v-btn v-if="registrationAllowed" color="primary" @click="validateStep1()">
                            Continue
                          </v-btn>
                          <p v-else class="caption red--text">Institution has disabled student registration.</p>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2" class="pa-0">
                      <v-row class="custom-row-height pa-0 ma-0" justify="center" align="center">
                        <v-col cols="12" sm="10" lg="8" class="pa-5" justify="center" align="center">
                          <v-text-field class="mb-3" v-model.trim="registrationDetails.fullname" label="Full Name *" solo clearable
                            rounded maxlength="100" @input="formValidityCheckers.fullname"
                            :error-messages="formErrors.fullname" hide-details="auto"
                          ></v-text-field>
                          <v-text-field class="mb-3" v-model.trim="registrationDetails.phone" label="Phone *" solo clearable rounded
                            maxlength="10" @keypress="isNumber" @input="formValidityCheckers.phone"
                            :error-messages="formErrors.phone" hide-details="auto"
                          ></v-text-field>
                          <v-text-field class="mb-3" v-model.trim="registrationDetails.email" label="Email" solo clearable rounded
                            maxlength="100" @input="formValidityCheckers.email" :error-messages="formErrors.email"
                            hide-details="auto"></v-text-field>
                          <!-- <v-text-field v-model.trim="registrationDetails.username" label="Username *" solo rounded
                            @input="formValidityCheckers.username" :error-messages="formErrors.username"
                            hide-details="auto">
                          </v-text-field>
                          <v-text-field v-model.trim="registrationDetails.password" label="Password *" rounded solo
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"
                            hide-details="auto" @input="formValidityCheckers.password"
                            :error-messages="formErrors.password">
                          </v-text-field>
                          <v-text-field v-model.trim="registrationDetails.confirmPassword" label="Confirm Password *"
                            rounded solo :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showConfirmPassword ? 'text' : 'password'"
                            @click:append="showConfirmPassword = !showConfirmPassword" hide-details="auto"
                            @input="formValidityCheckers.confirmPassword" :error-messages="formErrors.confirmPassword"
                          >
                          </v-text-field> -->
                          <v-btn color="primary" @click="validateStep2()">
                            Continue
                          </v-btn>
                          <v-btn text @click="vStepperStep = 1">
                            Previous
                          </v-btn>
                        </v-col>
                    </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="3" class="pa-0">
                      <v-row class="custom-row-height pa-0 ma-0" justify="center" align="center">
                        <v-col cols="12" sm="10" lg="8" class= "pa-5" justify="center" align="center">
                          <v-select class="mb-3" v-model="registrationDetails.classroom" return-object :items="allRooms"
                            item-text="sections" label="Classroom *" solo rounded hide-details="auto"
                            @input="formValidityCheckers.classroom" :error-messages="formErrors.classroom"
                            :disabled="!institutionLoaded"></v-select>
                          <v-btn color="primary" @click="register">
                            Register
                          </v-btn>
                          <v-btn text @click="vStepperStep = 2">
                            Previous
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                      <v-row class="custom-row-height pa-0 ma-0" justify="center" align="center">
                        <v-col cols="12" sm="10" lg="8" class="pa-5 mb-5" justify="center" align="center">
                          <v-btn large block color="green" text style="pointer-events: none;">
                            Registered<v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                          </v-btn>
                          <p class="caption">You will be notified by an email when your account gets approved. You can login with your phone number as your password.</p>
                          <v-btn large color="primary" @click="goToLogin">Login to Proceed</v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-form>
              <p class="ma-0 py-3 text-caption" align="center">
                Have trouble registering?
                <a @click="showSupportSheet = true">Contact Support</a>
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>

    <v-bottom-sheet scrollable persistent :width="$vuetify.breakpoint.mdAndDown ? '100%' : '60%'"
      :value="showSupportSheet" @click:outside="showSupportSheet = false">
      <v-card>
        <v-card-title class="pb-2">
          Contact Support<v-spacer></v-spacer>
          <v-btn icon @click="showSupportSheet = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="mb-4">
          <v-list-item href="tel:+919172261698">
            <v-list-item-avatar>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>+91 9172261698</v-list-item-title>
            <v-list-item-action>
              <v-icon>mdi-arrow-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import Helper from "@utils/misc";
import { mapActions, mapGetters } from "vuex";
import endpoints from "@api/repository";
import api from "@api/index";
import debounce from "debounce";

export default {
  name: "RegisterUser",
  data() {
    return {
      appTitle: endpoints.appTitle,
      vStepperStep: 1,
      isRegistered: false,
      institutionLoaded: false,
      showSupportSheet: false,
      filteredInstitutions: [],
      allInstitutions: [],
      filteredInstitutionsByNameOrCode: null,
      debouncedGetFilteredInstitutionsByNameOrCode: null,
      debouncedGetFilteredInstitutionsByPincode: null,
      defaultInstitutionCode: null,
      default_institution_registration: false,
      allRooms: [],
      otherInstitutionLabel: null,
      defaultLabel: "Institution *",

      registrationDetails: {
        pincode: null,
        institution: {},
        custom_institution_name: null,
        fullname: null,
        phone: null,
        email: null,
        classroom: null,
      },

      formErrors: {
        pincode: null,
        institution: null,
        custom_institution_name: null,
        fullname: null,
        username: null,
        phone: null,
        classroom: null,
      },

      formValidityCheckers: {
        pincode: async () => {
          var val = this.registrationDetails.pincode;
          if (val && val.length != 6) {
            this.formErrors.pincode = "PIN should be 6 digits"; return false;
          } else {
            this.formErrors.pincode = null; return true;
          }
        },

        institution: async () => {
          var val = this.registrationDetails.institution;
          if (!val || Object.keys(val).length == 0) {
            this.formErrors.institution = "Required"; return false;
          } else {
            this.formErrors.institution = null; return true;
          }
        },

        custom_institution_name: async () => {
          if (this.default_institution_registration && !this.registrationDetails.custom_institution_name) {
            this.formErrors.custom_institution_name = "Required"; return false;
          } else {
            this.formErrors.custom_institution_name = null; return true;
          }
        },

        fullname: async () => {
          if (!this.registrationDetails.fullname) {
            this.formErrors.fullname = "Required"; return false;
          } else {
            this.formErrors.fullname = null; return true;
          }
        },

        phone: async () => {
          return this.validatePhoneNumberDebounceFunction(this.registrationDetails.phone, this);
        },

        email: async () => {
          var val = this.registrationDetails.email;
          // eslint-disable-next-line no-useless-escape
          if (val && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
            this.formErrors.email = "Email is invalid."; return false;
          } else {
            this.formErrors.email = null; return true;
          }
        },

        // username: async () => {
        //   return this.validateUsernameDebouceFunction(this.registrationDetails.username, this);
        // },

        // password: async () => {
        //   var val = this.registrationDetails.password;
        //   if (!val) {
        //     this.formErrors.password = "Required"; return false;
        //   } else if (! /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(val)) {
        //     this.formErrors.password = "Password must contain - At least 8 Characters, 1 Uppercase alphabet, 1 Digit, 1 Special Character";
        //     return false;
        //   } else {
        //     this.formErrors.password = null; return true;
        //   }
        // },

        // confirmPassword: async () => {
        //   var val = this.registrationDetails.confirmPassword;
        //   if (!val) {
        //     this.formErrors.confirmPassword = "Required"; return false;
        //   } else if (val != this.registrationDetails.password) {
        //     this.formErrors.confirmPassword = "Passwords do not match"; return false;
        //   } else {
        //     this.formErrors.confirmPassword = null; return true;
        //   }
        // },

        classroom: async () => {
          var val = this.registrationDetails.classroom;
          if (!val || Object.keys(val).length == 0) {
            this.formErrors.classroom = "Required"; return false;
          } else {
            this.formErrors.classroom = null; return true;
          }
        },
      }
    };
  },


  computed: {
    ...mapGetters([
      "accessToken",
      "isLoading",
    ]),

    essentials() {
      return {
        accessToken: this.accessToken,
        setLoading: this.setLoading,
      };
    },

    registrationAllowed () {
      if (this.institutionLoaded) {
        var institution = this.registrationDetails.institution;
        return institution.preferences && institution.preferences.allow_student_registration;
      }
      return true;
    }
  },

  methods: {
    ...mapActions([
      "showSnackbar",
      "setLoading",
      "setAllRooms",
    ]),

    async validateStep1(){
      if(await this.formValidityCheckers.institution() &&
         await this.formValidityCheckers.custom_institution_name())
         this.vStepperStep = 2;
    },

    async validateStep2(){
      if(await this.formValidityCheckers.fullname() &&
         await this.formValidityCheckers.phone() &&
         await this.formValidityCheckers.email()
      )
        this.vStepperStep = 3;
    },

    async goToLogin() {
      this.$router.push({
        name: "LoginUser",
        params: { code: this.registrationDetails.institution.code },
      });
    },

    async register() {
      var valid = true;
      for (const key of Object.keys(this.registrationDetails))
        if (!await this.formValidityCheckers[key](this.registrationDetails[key])) valid = false;

      if (!valid){ 
        this.showSnackbar({
          text: "Fill all the details correctly",
          type: "error",
        });
        return false;
      }

      this.setLoading(true);

      var body = {
        ...this.registrationDetails,
        default_institution_registration: `${this.default_institution_registration}`,
      };

      api.call(this.essentials, endpoints.registerStudent, api.Methods.POST, body)
      .then(() => {
        this.showSnackbar({
          text: "Registered",
          type: "success",
        });
        this.isRegistered = true;
        this.vStepperStep = 4;
      })
      .catch(() => {
        this.showSnackbar({
          text: "Internal Server Error",
          type: "error",
        });
      });

      this.formErrors.username = null;
      this.setLoading(false);
    },

    // validateUsernameDebouceFunction: debounce(async function (val, self) {
    //   if (val)
    //     self.registrationDetails.username = val = val.replace(/ /g, ".").trim().toLowerCase();
    //   if (!val) {
    //     self.formErrors.username = "Required"; return false;
    //   }
    //   else if (! /^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/.test(val) || val.length < 5) {
    //     self.formErrors.username = "Username should be minimum 5 characters containing a-z, 0-9 or ."; return false;
    //   }
    //   var url = Helper.addUrlParams(endpoints.checkUsernameExists, [
    //     `username=${val}`,
    //   ]);
    //   if (await api.call(self.essentials, url) && !this.isRegistered) {
    //     self.formErrors.username = "Username is already taken."; return false;
    //   } else {
    //     self.formErrors.username = null; return true;
    //   }
    // }, 500),

    validatePhoneNumberDebounceFunction: debounce(async function (val, self) {
      if (!val) {
        self.formErrors.phone = "Required"; return false;
      } else if (val.length < 10) {
        self.formErrors.phone = "Phone number should have 10 digits"; return false;
      }

      var url = Helper.addUrlParams(endpoints.checkPhoneNumberExists, [
        `phone=${val}`,
      ]);

      if (await api.call(self.essentials, url) && !this.isRegistered) {
        self.formErrors.phone = "Phone number is already registered."; return false;
      } else {
        self.formErrors.phone = null; return true;
      }
    }, 500),

    isNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault();
    },

    async getDefaultInstitutionCode() {
      api.call(this.essentials, endpoints.getDefaultInstitutionCode)
        .then((res) => this.defaultInstitutionCode = res)
        .catch(() => { });
    },

    async getAllInstitutions() {
      var url = Helper.addUrlParams(endpoints.institutionsViewSet, [
        "simple=true",
        "preferences__allow_student_registration=true",
        "cp=2",
      ])
      api.call(this.essentials, url, api.Methods.GET)
        .then((res) => {
          this.allInstitutions = res;
          this.filteredInstitutions = res;
        })
        .catch((err) => {
          console.log(err);
          this.showSnackbar({
            text: "Unable to fetch institutions.",
            type: "error"
          })
        });
    },

    async onInstitutionSelect(institution) {
      this.institutionLoaded = this.formValidityCheckers.institution(institution);
      this.default_institution_registration = false;
      this.otherInstitutionLabel = null;
      if (this.institutionLoaded && this.registrationAllowed)
        this.getAllRooms(institution.code);
      else this.allRooms = [];
    },

    async onOtherInstitutionSelect() {
      this.default_institution_registration = true;
      this.institutionLoaded = true;
      this.otherInstitutionLabel = "Other";
      this.registrationDetails.institution = { code: this.defaultInstitutionCode, preferences: { allow_student_registration: true } };
      this.getAllRooms(this.defaultInstitutionCode);
    },

    async getAllRooms(institution_code) {
      var url = endpoints.getAllRoomsWithTitleOnly + institution_code + "/";
      api.call(this.essentials, url, api.Methods.GET)
        .then((res) => this.allRooms = res)
        .catch((err) => {
          console.log(err);
          this.showSnackbar({
            text: "Unable to fetch classrooms.",
            type: "error"
          })
        });
    },

    async initDebounceFunctions() {
      this.debouncedGetFilteredInstitutionsByNameOrCode = debounce((val) => {
        var url = Helper.addUrlParams(endpoints.institutionsViewSet, [
          "simple=true",
          "preferences__allow_student_registration=true",
          "search=" + (val || ""),
          "cp=2",
        ]);
        api.call(this.essentials, url, api.Methods.GET)
          .then((res) => {
            this.filteredInstitutions = res;
          })
          .catch((err) => {
            console.log(err);
            this.showSnackbar({
              text: "Unable to fetch institutions.",
              type: "error"
            })
          });
      }, 500);

      this.debouncedGetFilteredInstitutionsByPincode = debounce(async(val) => {
        if (val && await this.formValidityCheckers.pincode()) {
          var url = Helper.addUrlParams(endpoints.institutionsViewSet, [
            "simple=true",
            "preferences__allow_student_registration=true",
            "cp=2",
            `pincode=${val}`
          ])
          api.call(this.essentials, url, api.Methods.GET)
            .then((res) => { this.filteredInstitutions = res })
            .catch((err) => {
              console.log(err);
              this.showSnackbar({
                text: "Unable to fetch institutions.",
                type: "error"
              })
            });
        }
        else {
          this.filteredInstitutions = [...this.allInstitutions];
        }
      }, 500);
    }

  },

  async created() {
    this.getDefaultInstitutionCode();
    this.getAllInstitutions();
    this.initDebounceFunctions();
  },

  beforeUnmount() {
    this.debouncedGetFilteredInstitutionsByNameOrCode.cancel();
    this.debouncedGetFilteredInstitutionsByPincode.cancel();
  },

  watch: {
    filteredInstitutionsByNameOrCode(val) {
      this.debouncedGetFilteredInstitutionsByNameOrCode(val);
    },
  },
};
</script>

<style scoped>
.hide-scroll-bar {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.hide-scroll-bar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.register-user-container {
  z-index: 10;
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px 0px 0px 0px;
}

.custom-title {
  font-size: 16px;
}

::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}

.custom-row-height {
  min-height: 300px !important;
}
</style>